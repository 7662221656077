import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { BreadcrumbsWithIcon } from "../components/atoms/Breadcrumbs";
import { FattureTable } from "../components/wrapper/FattureTable";
import { ROUTE_NOLLEGGI_CONTRATTI } from "../Utils/routes";
import axios from "axios";

const Fatture = function () {
  const [preventivi, setPreventivi] = useState([]);
  const [loadingPreventivi, setLoadingPreventivi] = useState(true);

  const getPreventivi = async () => {
    setLoadingPreventivi(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/preventivi/noleggi/`)
      .then((response) => {
        setPreventivi(response?.data);
        setTimeout(() => setLoadingPreventivi(false), 2000);
      });
  }

  useEffect(() => {
    getPreventivi();
  },[]);

  return (
    <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
      <div>
        <h1 className="text-3xl font-bold">
          <Typography className="my-2" variant="h4" color="black">
            PREVENTIVI
          </Typography>
        </h1>
        <BreadcrumbsWithIcon
          breadcrumbElements={
            [
              {
                label: "NOLEGGI",
                link: ROUTE_NOLLEGGI_CONTRATTI,
              },
              {
                label: "PREVENTIVI"
              }
            ]
          }
        />
      </div>
      <FattureTable
        loadingPreventivi={loadingPreventivi}
        elements={preventivi}
        keysToShow={["id", "data_emissione"]}
      />
    </div>
  );
};

export default Fatture;
