import { Input } from "@material-tailwind/react";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function ClientModal({buttonToOpenModal, client}) {
  const [showModal, setShowModal] = React.useState(false);
  const defaultData = client ?? {
    'ragione_sociale' : null,
    'p_iva': null,
    'indirizzo_legale': null,
    'citta_legale' : null,
    'provincia_legale' : null,
    'indirizzo_pec' : null,
    'numero_telefono' : null,
  };
  const [clientData, setClientData] = useState(defaultData);

  const setFormData = (key, value) => {
    setClientData({
        ...clientData,
        [key] : value,
    });
  }

  const salvaCliente = async () => {
    if (client) {
      return await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/clienti/${client?.id}`,
          clientData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          setShowModal(false);
          setClientData(defaultData);
          toast.success("Cliente modificato con successo!", {
            position: "top-center",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          toast.error("Errore durante la creazione  del cliente!", {
            position: "top-center",
          });
        });
    }
    return await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/clienti`, clientData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setShowModal(false);
        setClientData(defaultData);
        toast.success("Cliente salvato con successo!", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Errore durante il salvataggio del cliente!", {
          position: "top-center",
        });
      });
  };

  return (
    <>
      {buttonToOpenModal(() => setShowModal(!showModal))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 border-red-300 shadow-lg min-w-[30vw] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {client ? "MODIFICA CLIENTE" : "NUOVO CLIENTE"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className="relative p-6 flex flex-col gap-4">
                  <h2 className="sectionTitleModal">LEGALI</h2>
                  <Input
                    label={"INSERISCI RAGIONE SOCIALE..."}
                    value={clientData["ragione_sociale"]}
                    onChange={(e) =>
                      setFormData("ragione_sociale", e.target?.value)
                    }
                  />
                  <Input
                    label={"INSERISCI P.IVA..."}
                    value={clientData["p_iva"]}
                    onChange={(e) => setFormData("p_iva", e.target?.value)}
                  />
                  <Input
                    label={"INSERISCI INDIRIZZO..."}
                    value={clientData["indirizzo_legale"]}
                    onChange={(e) =>
                      setFormData("indirizzo_legale", e.target?.value)
                    }
                  />
                  <Input
                    label={"INSERISCI CITTA..."}
                    value={clientData["citta_legale"]}
                    onChange={(e) =>
                      setFormData("citta_legale", e.target?.value)
                    }
                  />
                  <Input
                    label={"INSERISCI PROVINCIA..."}
                    value={clientData["provincia_legale"]}
                    onChange={(e) =>
                      setFormData("provincia_legale", e.target?.value)
                    }
                  />
                  <h2 className="sectionTitleModal">CONTATTI</h2>
                  <Input
                    label={"INDIRIZZO PEC..."}
                    value={clientData["indirizzo_pec"]}
                    onChange={(e) =>
                      setFormData("indirizzo_pec", e.target?.value)
                    }
                  />
                  <Input
                    label={"NUMERO DI TELEFONO..."}
                    value={clientData["numero_telefono"]}
                    onChange={(e) =>
                      setFormData("numero_telefono", e.target?.value)
                    }
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setClientData(defaultData);
                    }}
                  >
                    ANNULLA
                  </button>
                  <button
                    className="bg-red-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      salvaCliente();
                      setShowModal(false);
                      setClientData(defaultData);
                    }}
                  >
                    {client ? "SALVA CLIENTE" : "CREA CLIENTE"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
