import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { BreadcrumbsWithIcon } from "../components/atoms/Breadcrumbs";
import { ContrattiTable } from "../components/wrapper/ContrattiTable";
import axios from "axios";

const Noleggi = function () {
  const [contratti, setContratti] = useState([]);
  const [loadingContratti, setLoadingContratti] = useState(true);

   const getContratti = async () => {
     setLoadingContratti(true);
     return await axios
       .get(`${process.env.REACT_APP_BACKEND_URL}/contratti/noleggi/`)
       .then((response) => {
         setContratti(response?.data);
         setTimeout(() => setLoadingContratti(false), 2000);
       });
   };

  useEffect(() => {
    getContratti();
  }, []);

  return (
    <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
      <div>
        <h1 className="text-3xl font-bold">
          <Typography className="my-2" variant="h4" color="black">
            Noleggi
          </Typography>
        </h1>
        <BreadcrumbsWithIcon
          breadcrumbElements={
            [
              {
                label: "NOLEGGI",
              },
              {
                label: "CONTRATTI"
              }
            ]
          }
        />
      </div>
      <ContrattiTable loadingContratti={loadingContratti} elements={contratti} keysToShow={['ultima_fatturazione', 'prossima_fatturazione']} />
    </div>
  );
};

export default Noleggi;
