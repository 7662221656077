import { Card, CardBody, Typography } from "@material-tailwind/react";
import TitoloBoxGrande from "../../../components/wrapper/TitoloBoxGrande";
import CampoBoxGrande from "../../../components/wrapper/CampoBoxGrande";
import Divisore from "../../../components/atoms/Divisore";
import Prezzo from "../../../components/wrapper/Prezzo";

const TrashCosts = ({ preventivo, withIva }) => {
    const trashCosts = preventivo?.trashCosts;
  const iva = withIva ? trashCosts * 0.22 : 0;
  const totaleFattura = trashCosts + iva;

  return (
    <Card className="my-4 rounded-none da-stampare">
      <CardBody>
        <TitoloBoxGrande titolo={"SMALTIMENTO"} />

        <CampoBoxGrande titoloCampo={"Costo"} tipo="custom">
          <Prezzo valore={trashCosts} />
        </CampoBoxGrande>

        <CampoBoxGrande titoloCampo={"IVA"}>
          <Prezzo valore={iva} />
        </CampoBoxGrande>

        <Divisore />

        <CampoBoxGrande titoloCampo={"Totale (costo + IVA)"} tipo="custom">
          <Prezzo valore={totaleFattura} variant="lead" color="green" />
        </CampoBoxGrande>
      </CardBody>
    </Card>
  );
};

export default TrashCosts;
