import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import {  } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";

export function SimpleCard({ 
    CardIcon = Square3Stack3DIcon,  
    cardColor = 'white',
    backgroundColor = 'white',
    topText,
    bottomText,
    ...props 
}) {

  const lightenColor = function(color, factor) {
    // Convert hex to RGB
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.substring(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    // Convert RGB to hex
    const rgbToHex = (r, g, b) => {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    };

    const rgbColor = hexToRgb(color);

    // Adjust each RGB component
    for (let i = 0; i < 3; i++) {
      rgbColor[i] = Math.round(rgbColor[i] + (255 - rgbColor[i]) * factor);
    }

    return rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
  };


  return (
    <Card className={`mt-3 bg-${backgroundColor} w-full rounded-none`}>
      <CardBody
        floated={false}
        shadow={false}
        color="transparent"
        className="flex flex-col gap-2 p-3 rounded-none md:flex-row md:items-center"
      >
        <div
          className={`flex w-10 h-10 justify-center items-center rounded-full p-2 text-white`}
          style={{ backgroundColor: lightenColor(cardColor, 0.75) }}
        >
          <CardIcon color={cardColor} className="h-10 w-10" />
        </div>
        <div>
          <Typography
            variant="small"
            color="gray"
            className="max-w-sm font-small"
          >
            {topText}
          </Typography>
          <Typography variant="h6" color="black">
            {bottomText}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
}
