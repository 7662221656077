import { Typography } from "@material-tailwind/react";

const Prezzo = ({ valore, appendNotes = '', color = 'gray', variant='paragraph',  valuta = '€'}) => {
    return (
      <Typography color={color} variant={variant}>
        {`${valuta}${valore?.toFixed(2)} ${appendNotes}`}
      </Typography>
    );
} 

export default Prezzo;