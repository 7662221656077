import React, { useEffect, useState } from "react";
import {
    Button,
  Card,
  CardBody,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@material-tailwind/react";

import {
  Link,
  useParams,
} from "react-router-dom";
import { CreditCardIcon, PencilIcon, PrinterIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ROUTE_NOLLEGGI_FATTURE, ROUTE_PRODOTTI } from "../../Utils/routes";
import { PERIODI_FATTURAZIONE, STATI_PAGAMENTO, TIPI_CONTRATTO, TIPOLOGIE_PRODOTTI } from "../../Utils/consts";
import ModaleProdotto from "../../components/wrapper/ModaleProdotto";
import ModaleEliminaElemento from "../../components/wrapper/ModaleEliminaElemento";
import ModalePreventivo from "../../components/wrapper/ModalePreventivo";
import axios from "axios";
import {  Triangle } from "react-loader-spinner";
import { toast } from "react-toastify";

const Contratto = function () {
  const { idContratto } = useParams();
  const [contratto, setContratto] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [loadingProdotti, setLoadingProdotti] = useState(true);
  const [loadingPreventivi, setLoadingPreventivi] = useState(true);
  const [loadingContratto, setLoadingContratto] = useState(true);
  const [prodottiContratto, setProdottiContratto] = useState([]);
  const [preventiviContratto, setPreventiviContratto] = useState([]);


  const getContratto = async () => {
    setLoadingContratto(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contratti/noleggi/` + idContratto)
      .then((response) => {
        setContratto(response?.data);
        getCliente(response.data.id_cliente);
        setTimeout(() => setLoadingContratto(false), 2000);
        getProdottiContratto();
        getPreventiviContratto();
      });
  };

  const getCliente = async (idCliente) => {
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/clienti/` + idCliente)
      .then((response) => {
        setCliente(response?.data);
      });
  };


  const eliminaPreventivo = async (idPreventivo) => {
  return await axios
    .delete(`${process.env.REACT_APP_BACKEND_URL}/preventivi/${idPreventivo}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      toast.success("Preventivo eliminato con successo!", {
        position: "top-center",
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
    .catch(() => {
      toast.error("Errore durante l'eliminazione del preventivo!", {
        position: "top-center",
      });
    });
  };

  const buttonAddNewProduct = (functionOnClick) => (
    <Button
      onClick={() => functionOnClick()}
              className="flex items-center gap-3 rounded-none bg-[#bc000ad9]"
              size="sm"
            >
              <PrinterIcon strokeWidth={2} className="h-4 w-4" /> Aggiungi
              Macchina
            </Button>
  );

  const buttonEditProduct = (functionOnClick) => (
    <Tooltip content="Modifica macchina">
      <IconButton
        className="rounded-none"
        variant="outlined"
        onClick={() => functionOnClick()}
        color="black"
        size="sm"
      >
        <PencilIcon className="h-4 w-4" />
      </IconButton>
    </Tooltip>
  );

  const buttonDeleteProduct = (functionOnClick) => (
    <Tooltip content="Elimina macchina">
      <IconButton
        className="rounded-none"
        variant="outlined"
        onClick={() => functionOnClick()}
        color="red"
        size="sm"
      >
        <TrashIcon className="h-4 w-4" />
      </IconButton>
    </Tooltip>
  );

  const buttonDeleteInvoice = (functionOnClick) => (
    <Tooltip content="Elimina preventivo">
      <IconButton
        className="rounded-none"
        variant="outlined"
        onClick={() => functionOnClick()}
        color="red"
        size="sm"
      >
        <TrashIcon className="h-4 w-4" />
      </IconButton>
    </Tooltip>
  );

  const buttonAddNewPreventivo = (functionOnClick) => (
    <Button
      onClick={() => functionOnClick()}
      disabled={prodottiContratto.length === 0}
      className="flex items-center gap-3 rounded-none bg-red-700"
      size="sm"
    >
      <CreditCardIcon strokeWidth={2} className="h-4 w-4" /> Emetti nuovo
      preventivo fattura
    </Button>
  );

  const getProdottiContratto = async () => {
    setLoadingProdotti(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/prodotti/contratto/` + idContratto)
      .then((response) => {
        setProdottiContratto(response?.data);
        setTimeout(() => setLoadingProdotti(false), 2000);
      });
  };

  const getPreventiviContratto = async () => {
    setLoadingPreventivi(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/preventivi/noleggi/` + idContratto)
      .then((response) => {
        setPreventiviContratto(response?.data);
        setTimeout(() => setLoadingPreventivi(false), 2000);
      });
  };

  useEffect(() => {
    getContratto();
  }, []);

  return (
    <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
      <div>
        <Typography className="my-2 mb-4" color="gray" variant="h5">
          CONTRATTO (#{contratto?.id})
        </Typography>
      </div>
      <div className="flex gap-2 h-fit ">
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingContratto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  DATI
                </Typography>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Data emissione:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {contratto?.["data_emissione"] ?? "Non trovato"}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Data scadenza:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {contratto?.["data_fine_contratto"] ?? "Non trovato"}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Tipo contratto:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {TIPI_CONTRATTO[contratto?.["contract_type"]] ??
                      "Non trovato"}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Periodo fatturazione:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {PERIODI_FATTURAZIONE[
                      contratto?.["periodo_fatturazione"]
                    ] ?? "Non trovato"}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Ultima fatturazione:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {contratto?.["ultima_fatturazione"] ?? "Non trovato"}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Prossima fatturazione:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {contratto?.["prossima_fatturazione"] ?? "Non trovato"}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingContratto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  CANONE & PREZZI
                </Typography>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Pagine B/N incluse nel canone:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {contratto?.["fogli_base_b_w"] ?? 0}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Pagine a colori incluse nel canone:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {contratto?.["fogli_base_colore"] ?? 0}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Canone mensile:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {"€" + (contratto?.["prezzo_base"] ?? 0)?.toFixed(2)}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Prezzo pagina B/N extra:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {"€" + (contratto?.["prezzo_bw"] ?? 0).toFixed(2)}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Prezzo pagina a colori extra:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {"€" + (contratto?.["prezzo_col"] ?? 0)?.toFixed(2)}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingContratto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  CLIENTE
                </Typography>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Ragione sociale:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["ragione_sociale"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>P.IVA:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["p_iva"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Indirizzo PEC:</b>
                  </Typography>
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ textAlign: "left" }}
                  >
                    {cliente?.["indirizzo_pec"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Numero di telefono:</b>
                  </Typography>
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ textAlign: "left" }}
                  >
                    {cliente?.["numero_telefono"]}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
      <Card className="mt-4 rounded-none">
        <CardBody>
          {!loadingProdotti ? (
            <div className="flex justify-between items-center">
              <Typography className="mb-2" color="gray" variant="h5">
                MACCHINE
              </Typography>
              <ModaleProdotto
                buttonToOpenModal={buttonAddNewProduct}
                idContratto={idContratto}
              />
            </div>
          ) : undefined}
          <div>
            {loadingProdotti ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : prodottiContratto.length > 0 ? (
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {[
                      "numero_serie",
                      "indirizzo_macchina",
                      "città_macchina",
                      "tipologia_macchina",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head.toUpperCase().replace("_", " ")}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {prodottiContratto.map((element, index) => {
                    const isLast = index === prodottiContratto.length - 1;
                    const classes = isLast
                      ? "p-4 border-b border-blue-gray-50"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={element?.id}>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Link
                              to={ROUTE_PRODOTTI + element.id}
                              variant="small"
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                                fontWeight: "bold",
                              }}
                            >
                              {element?.["numero_di_serie"]}
                            </Link>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {element?.["indirizzo_prodotto"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {element?.["citta_prodotto"]}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {
                                TIPOLOGIE_PRODOTTI[
                                  element?.["tipologia_prodotto"]
                                ]
                              }
                            </Typography>
                          </div>
                        </td>
                        <td className={classes + " flex gap-2 justify-end"}>
                          <ModaleEliminaElemento
                            elementToDelete={element}
                            buttonToOpenModal={buttonDeleteProduct}
                          />
                          <ModaleProdotto
                            product={element}
                            buttonToOpenModal={buttonEditProduct}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <Typography className="mb-2" color="gray" variant="paragraph">
                Ancora nessuna macchina aggiunta per questo contratto!
              </Typography>
            )}
          </div>
        </CardBody>
      </Card>
      <Card className="mt-4 rounded-none">
        <CardBody>
          <div className="flex justify-between items-center">
            {!loadingPreventivi ? (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  PREVENTIVI
                </Typography>
                <Tooltip
                  content={
                    prodottiContratto.length > 0
                      ? "Crea preventivo"
                      : "Nessuna macchina aggiunta al contratto!"
                  }
                >
                  <div>
                    <ModalePreventivo
                      buttonToOpenModal={buttonAddNewPreventivo}
                      contratto={contratto}
                      prodotti={prodottiContratto}
                    />
                  </div>
                </Tooltip>
              </>
            ) : undefined}
          </div>
          <div>
            {loadingPreventivi ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : preventiviContratto.length > 0 ? (
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {[
                      "id_fattura",
                      "data_emissione",
                      "stato",
                      "totale",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head.toUpperCase().replace("_", " ")}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {preventiviContratto.map((element, index) => {
                    const isLast = index === preventiviContratto.length - 1;
                    const classes = isLast
                      ? "p-4 border-b border-blue-gray-50"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={element?.id}>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Link
                              to={ROUTE_NOLLEGGI_FATTURE + element.id}
                              variant="small"
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                                fontWeight: "bold",
                              }}
                            >
                              {"# " + element?.["id"]}
                            </Link>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {element?.["data_emissione"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex justify-start">
                            <Chip
                              color={element["pagato"] ? "green" : "red"}
                              size="sm"
                              variant="ghost"
                              value={STATI_PAGAMENTO[element["pagato"]]}
                            />
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {"€" + element?.["prezzo_totale"]}
                          </Typography>
                        </td>
                        <td className={classes + " flex gap-2 justify-end"}>
                          <ModaleEliminaElemento
                            functionForDelete={() =>
                              eliminaPreventivo(element?.id)
                            }
                            buttonToOpenModal={buttonDeleteInvoice}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <Typography className="mb-2" color="gray" variant="paragraph">
                Ancora nessun preventivo fattura emesso per questo contratto!
              </Typography>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Contratto;
