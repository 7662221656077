import { Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { SelectWithTitle } from "./SelectWithTitle";
import { toast } from "react-toastify";
import axios from "axios";

export default function ModaleProdotto({ buttonToOpenModal, product, idContratto }) {
  const [showModal, setShowModal] = React.useState(false);
  const defaultData = product ?? {
    tipo_prodotto: null,
    numero_di_serie: null,
    indirizzo_prodotto: null,
    id_contratto: idContratto,
    citta_prodotto: null,
    provincia_prodotto: null,
    tipologia_prodotto: null,
  };
  
  const CATEGORIE_PRODOTTI = [
    {
        label: 'Stampante',
        value: 0,
    }
  ];
  const TIPOLOGIE_STAMPANTI = [
    {
        label: 'Macchina bianco e nero',
        value: 0,
    },
    {
        label: 'Macchina a colori',
        value: 1,
    }
  ];

  const [productData, setProductData] = useState(defaultData);

  const setFormData = (key, value) => {
    setProductData({
      ...productData,
      [key]: value,
    });
  };

  const [productType, setProductType] = useState({label: '', value: 0});
  const [productTypology, setProductTypology] = useState({label: '', value: 0});

  const salvaProdotto = async () => {
    if (product) {
      return await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/prodotti/${product?.id}`,
          productData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          setShowModal(false);
          setProductData(defaultData);
          toast.success("Prodotto modificato con successo!", {
            position: "top-center",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          toast.error("Errore durante la creazione del prodotto!", {
            position: "top-center",
          });
        });
    }
    return await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/prodotti/`, productData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setShowModal(false);
        setProductData(defaultData);
        toast.success("Prodotto salvato con successo!", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Errore durante il salvataggio del prodotto!", {
          position: "top-center",
        });
      });
  };


  useEffect(() => {
    setFormData('tipo_prodotto', productType?.value);
  }, [productType]);

  useEffect(() => {
    setFormData('tipologia_prodotto', productTypology?.value);
  }, [productTypology]);
  return (
    <>
      {buttonToOpenModal(() => setShowModal(!showModal))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 border-red-300 shadow-lg min-w-[30vw] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {product ? "MODIFICA PRODOTTO" : "NUOVO PRODOTTO"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className="relative p-6 flex flex-col gap-4">
                  <h2 className="sectionTitleModal">INFORMAZIONI</h2>
                  <Input
                    label={"INSERISCI NUMERO DI SERIE..."}
                    value={productData["numero_di_serie"]}
                    onChange={(e) =>
                      setFormData("numero_di_serie", e.target?.value)
                    }
                  />
                  <SelectWithTitle
                    width="w-full"
                    title="CATEGORIA PRODOTTO"
                    options={CATEGORIE_PRODOTTI}
                    defaultValue={productData?.["tipo_prodotto"]}
                    onSelectFunc={setProductType}
                  />
                  <SelectWithTitle
                    width="w-full"
                    title="TIPOLOGIA STAMPANTE"
                    options={TIPOLOGIE_STAMPANTI}
                    defaultValue={productData?.["tipologia_prodotto"]}
                    onSelectFunc={setProductTypology}
                  />
                  <h2 className="sectionTitleModal">POSIZIONE</h2>
                  <Input
                    label={"INSERISCI INDIRIZZO..."}
                    value={productData["indirizzo_prodotto"]}
                    onChange={(e) =>
                      setFormData("indirizzo_prodotto", e.target?.value)
                    }
                  />
                  <Input
                    label={"INSERISCI CITTA..."}
                    value={productData["citta_prodotto"]}
                    onChange={(e) =>
                      setFormData("citta_prodotto", e.target?.value)
                    }
                  />
                  <Input
                    label={"INSERISCI PROVINCIA..."}
                    value={productData["provincia_prodotto"]}
                    onChange={(e) =>
                      setFormData("provincia_prodotto", e.target?.value)
                    }
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setProductData(defaultData);
                    }}
                  >
                    ANNULLA
                  </button>
                  <button
                    className="bg-red-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      salvaProdotto();
                    }}
                  >
                    {product ? "SALVA PRODOTTO" : "CREA PRODOTTO"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
