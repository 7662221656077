import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Chip,
  Typography,
} from "@material-tailwind/react";
import { Link, useParams} from "react-router-dom";
import { ROUTE_NOLLEGGI_CONTRATTI } from "../../Utils/routes";
import axios from "axios";
import { Triangle } from "react-loader-spinner";

const Cliente = function () {
  const { idCliente } = useParams();
  const [contrattiCliente, setContrattiCliente] = useState([]);
  const [loadingContratti, setLoadingContratti] = useState(true);
   const [cliente, setCliente] = useState(null);
   const [loadingCliente, setLoadingCliente] = useState(true);

   const getCliente = async () => {
     setLoadingCliente(true);
     return await axios
       .get(`${process.env.REACT_APP_BACKEND_URL}/clienti/${idCliente}`)
       .then((response) => {
         setCliente(response?.data);
         getContrattiCliente();
         setTimeout(() => setLoadingCliente(false), 2000);
       });
   };

   useEffect(() => {
     getCliente();
   }, []);
  
  const getContrattiCliente = async () => {
    setLoadingContratti(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contratti/noleggi/cliente/${idCliente}`)
      .then((response) => {
        setContrattiCliente(response?.data);
        setTimeout(() => setLoadingContratti(false), 2000);
      });
  }
  
  return (
    <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
      <div>
        <Typography className="my-2 mb-4" color="gray" variant="h5">
          CLIENTE (#{cliente?.id})
        </Typography>
      </div>
      <div className="flex gap-2 h-fit ">
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingCliente ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  INFORMAZIONI LEGALI
                </Typography>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Ragione sociale:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["ragione_sociale"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>P.IVA:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["p_iva"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Indirizzo:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["indirizzo_legale"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Città:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["citta_legale"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Provincia:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["provincia_legale"]}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingCliente ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  CONTATTI
                </Typography>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Indirizzo PEC:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["indirizzo_pec"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Numero di telefono:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {cliente?.["numero_telefono"]}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
      <Card className="mt-4 rounded-none">
        <CardBody>
          {!loadingContratti ? (
            <>
              <Typography className="mb-2" color="gray" variant="h5">
                CONTRATTI
              </Typography>
              <div>
                {contrattiCliente?.length > 0 ? (
                  <table className="mt-4 w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        {[
                          "id_contratto",
                          "tipo_pacchetto",
                          "data_fine",
                          "prossima_fatturazione",
                        ].map((head) => (
                          <th
                            key={head}
                            className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head.toUpperCase().replace("_", " ")}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {contrattiCliente?.map((element, index) => {
                        const isLast = index === contrattiCliente?.length - 1;
                        const classes = isLast
                          ? "p-4 border-b border-blue-gray-50"
                          : "p-4 border-b border-blue-gray-50";

                        return (
                          <tr key={element?.id}>
                            <td className={classes}>
                              <div className="flex flex-col">
                                <Link
                                  to={ROUTE_NOLLEGGI_CONTRATTI + element.id}
                                  variant="small"
                                  style={{
                                    textDecoration: "underline",
                                    color: "blue",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {"# " + element?.id}
                                </Link>
                              </div>
                            </td>
                            <td className={"flex " + classes}>
                              <Chip
                                color={
                                  (element["fogli_base_b_w"] ?? 0) !== 0 ||
                                  (element["fogli_base_colore"] ?? 0) !== 0
                                    ? "green"
                                    : "blue"
                                }
                                size="sm"
                                variant="ghost"
                                value={
                                  (element["fogli_base_b_w"] ?? 0) !== 0 ||
                                  (element["fogli_base_colore"] ?? 0) !== 0
                                    ? "Canone"
                                    : "A consumo"
                                }
                              />
                            </td>
                            <td className={classes}>
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {element?.["data_fine_contratto"]}
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {element?.["prossima_fatturazione"]}
                                </Typography>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <Typography className="mb-2" color="gray" variant="paragraph">
                    Ancora nessun contratto stipulato con questo cliente!
                  </Typography>
                )}
              </div>
            </>
          ) : (
            <Triangle
              visible={true}
              height="48"
              width="48"
              color="#c62b38"
              secondaryColor="#d6888f"
              ariaLabel="rings-loading"
              wrapperStyle={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              }}
              wrapperClass=""
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Cliente;
