import { Card, CardBody, Typography } from "@material-tailwind/react";
import TitoloBoxGrande from "../../../components/wrapper/TitoloBoxGrande";
import CampoBoxGrande from "../../../components/wrapper/CampoBoxGrande";
import Divisore from "../../../components/atoms/Divisore";
import Prezzo from "../../../components/wrapper/Prezzo";

const Cauzione = ({ preventivo}) => {
  const cauzione = preventivo?.cauzione;

  return (
    <Card className="my-4 rounded-none da-stampare">
      <CardBody>
        <TitoloBoxGrande titolo={"CAUZIONE"} />

        <CampoBoxGrande titoloCampo={"Costo"} tipo="custom">
          <Prezzo valore={cauzione} />
        </CampoBoxGrande>
      </CardBody>
    </Card>
  );
};

export default Cauzione;
