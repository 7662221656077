import { Avatar, Typography } from "@material-tailwind/react";

export function AvatarWithText() {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <div className="flex flex-col gap-2 p-0">
      <div className="flex items-center gap-4">
        <div class="circle">
          <span class="initials">VD</span>
        </div>
        <div>
          <Typography
          style={{
            overflow: 'hidden',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}  
          variant="h6" color="black">
            {user?.name + ' ' + user?.surname}
          </Typography>
        </div>
      </div>
    </div>
  );
}
