import { DocumentPlusIcon, PlusIcon, ScaleIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
  Button,
} from "@material-tailwind/react";
import ClientModal from "./ClientModal";

export function ButtonWithMenu() {
  return (
    <></>
    // <Menu>
    //   <MenuHandler>
    //     <Button
    //       variant="outlined"
    //       className="flex my-2 justify-start items-center p-2 border-none rounded-none shadow-sm bg-red-700 text-white gap-3  max-h-feat"
    //       size="md"
    //     >
    //       <Typography variant="small">Crea</Typography>
    //       <PlusIcon width={20} />
    //     </Button>
    //   </MenuHandler>
    //   <MenuList>
    //     <MenuItem className="flex items-center gap-2">
    //       <ClientModal buttonToOpenModal={(functionOnClick) => {
    //         return (
    //           <MenuItem onClick={()=> functionOnClick()} className="flex items-center gap-2" role="button">
    //             <UserPlusIcon width={20} />
    //             <Typography variant="small" className="font-medium">
    //               Cliente
    //             </Typography>
    //           </MenuItem>
    //         );
    //       }} />
    //     </MenuItem>
    //     <hr className="my-2 border-blue-gray-50" />
    //     <MenuItem className="flex items-center gap-2" role="button">
    //       <DocumentPlusIcon width={20} />
    //       <Typography variant="small" className="font-medium">
    //         Contratto
    //       </Typography>
    //     </MenuItem>
    //   </MenuList>
    // </Menu>
  );
}
