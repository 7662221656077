import { Card, CardBody, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { ROUTE_CLIENTI, ROUTE_NOLLEGGI_CONTRATTI } from "../../../Utils/routes";
import { STATI_PAGAMENTO } from "../../../Utils/consts";
import Prezzo from "../../../components/wrapper/Prezzo";
import CampoBoxGrande from "../../../components/wrapper/CampoBoxGrande";

const Riassunto = ({
    fattura,
    cliente
}) => {

    const campi = [
      {
        label: "Contratto",
        valore: null,
        tipo: "custom",
        figlio: (
          <Link
            to={ROUTE_NOLLEGGI_CONTRATTI + fattura?.["id_contratto"]}
            className="text-[blue] underline"
          >
            {"# " + fattura?.["id_contratto"]}
          </Link>
        ),
      },
      {
        label: "Stato",
        valore: STATI_PAGAMENTO[fattura?.pagato],
      },
      {
        label: "Cliente",
        valore: null,
        tipo: "custom",
        figlio: (
          <Link
            to={ROUTE_CLIENTI + cliente?.id}
            className="text-[blue] underline"
          >
            {cliente?.ragione_sociale}
          </Link>
        ),
      },
      {
        label: "P.IVA Cliente",
        valore: cliente?.p_iva ?? "Non disponibile",
      },
      {
        label: "PEC Cliente",
        valore: cliente?.indirizzo_pec,
      },
      {
        label: "Mese fatturazione",
        valore: fattura?.["mese_fatturazione"],
      },
      {
        label: "Pagine extra B/N",
        valore: fattura?.pagine_extra_bianco_e_nero,
      },
      {
        label: "Pagine extra a colori",
        valore: fattura?.pagine_extra_a_colori,
      },
      {
        label: "Totale",
        valore: null,
        tipo: "custom",
        figlio: (
          <Prezzo
            variant="lead"
            color="white"
            valore={fattura?.prezzo_totale ?? 0}
          />
        ),
      },
    ];

    return (
      <Card
        className={
          !fattura?.pagato
            ? "bg-[#bf4646] rounded-none da-stampare"
            : "bg-[#4aa84a] rounded-none da-stampare"
        }
      >
        <CardBody>
          {campi?.map((campo) => {
            return (
              <CampoBoxGrande
                titoloCampo={campo.label}
                valoreCampo={campo.valore}
                coloreCampo="white"
                coloreTitolo="white"
              >
                {campo.figlio ?? undefined}
              </CampoBoxGrande>
            );
          })}
        </CardBody>
      </Card>
    );
}

export default Riassunto;