import { Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { SelectWithTitle } from "./SelectWithTitle";
import axios from "axios";
import { toast } from "react-toastify";

export default function ModaleLettura({ buttonToOpenModal, prodotto, lettura }) {
  const [showModal, setShowModal] = React.useState(false);
  const defaultData = lettura ?? {
    data_lettura: null,
    id_prodotto: prodotto?.id,
    numero_pagine_totali: 0,
    numero_pagine_b_n: 0,
    numero_pagine_col: 0,
  };

  const [dataLettura, setDataLettura] = useState(defaultData);

  const setFormData = (key, value) => {
    setDataLettura({
      ...dataLettura,
      [key]: value,
    });
  };

  const salvaLettura = async () => {
    if(lettura) {
      return await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/letture_prodotto/${lettura?.id}`,
          dataLettura,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          setShowModal(false);
          setDataLettura(defaultData);
          toast.success("Lettura modificata con successo!", {
            position: "top-center",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          toast.error("Errore durante la creazione  della lettura!", {
            position: "top-center",
          });
        });
    }
    return await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/letture_prodotto`, dataLettura, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setShowModal(false);
        setDataLettura(defaultData);
        toast.success("Lettura salvata con successo!", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Errore durante il salvataggio della lettura!", {
          position: "top-center",
        });
      });
  };

  return (
    <>
      {buttonToOpenModal(() => setShowModal(!showModal))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 border-red-300 shadow-lg min-w-[30vw] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {lettura ? "MODIFICA LETTURA" : "NUOVA LETTURA"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className="relative p-6 flex flex-col gap-4">
                  <Input
                    label={"DATA LETTURA..."}
                    value={dataLettura["data_lettura"]}
                    onChange={(e) =>
                      setFormData("data_lettura", e.target?.value)
                    }
                  />
                  <Input
                    label={"PAGINE TOTALI..."}
                    value={dataLettura["numero_pagine_totali"]}
                    onChange={(e) =>
                      setFormData("numero_pagine_totali", e.target?.value)
                    }
                  />
                  <Input
                    label={"PAGINE B/N..."}
                    value={dataLettura["numero_pagine_b_n"]}
                    onChange={(e) =>
                      setFormData("numero_pagine_b_n", e.target?.value)
                    }
                  />
                  {
                    prodotto?.['tipologia_prodotto'] === 1 && <Input
                        label={"PAGINE A COLORI..."}
                        value={dataLettura["numero_pagine_col"]}
                        onChange={(e) =>
                          setFormData("numero_pagine_col", e.target?.value)
                        }
                      />
                  }
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setDataLettura(defaultData);
                    }}
                  >
                    ANNULLA
                  </button>
                  <button
                    className="bg-red-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      salvaLettura();
                    }}
                  >
                    {lettura ? "SALVA LETTURA" : "CREA LETTURA"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
