import React, { useEffect, useState } from "react";
import { SidebarWithLogo } from "./components/wrapper/sidebar";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Dashboard from "./Views/Dashboard";
import Clienti from "./Views/Clienti";
import Fatture from "./Views/Fatture";
import Noleggi from "./Views/Noleggi";
import Cliente from "./Views/Details/Cliente";
import Contratto from "./Views/Details/Contratto";
import Prodotto from "./Views/Details/Prodotto";
import Fattura from "./Views/Details/Fattura/Fattura";
import { ROUTE_CLIENTI, ROUTE_HOME, ROUTE_LOGIN, ROUTE_NOLLEGGI_CONTRATTI, ROUTE_NOLLEGGI_FATTURE, ROUTE_PRODOTTI } from "./Utils/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Login";

const App = () => {
  const [user, setUser] = useState(localStorage.getItem('user'));

  useEffect(() => {
    if(user && window.location.pathname === '/') {
      window.location.href = ROUTE_HOME;
    } else if(!user && window.location.pathname !== '/login') {
      window.location.href = ROUTE_LOGIN;
    }
  }, [])
  
  return (
    <Router>
      {user ? (
        <div className="flex max-h-[100vh] bg-[#f3f7fa]">
          <SidebarWithLogo />
          <ToastContainer />
          <Routes>
            <Route path={ROUTE_HOME} element={<Dashboard />} />
            <Route path={ROUTE_CLIENTI} element={<Clienti />} />
            <Route path={ROUTE_NOLLEGGI_FATTURE} element={<Fatture />} />
            <Route
              path={`${ROUTE_NOLLEGGI_FATTURE}:idPreventivo`}
              element={<Fattura />}
            />
            <Route path={ROUTE_NOLLEGGI_CONTRATTI} element={<Noleggi />} />
            <Route path={`${ROUTE_CLIENTI}:idCliente`} element={<Cliente />} />
            <Route
              path={`${ROUTE_NOLLEGGI_CONTRATTI}:idContratto`}
              element={<Contratto />}
            />
            <Route
              path={`${ROUTE_PRODOTTI}:idProdotto`}
              element={<Prodotto />}
            />
          </Routes>
        </div>
      ) : (
        <Routes>
          <Route
            path={ROUTE_LOGIN}
            element={
              <Login user={user} setUser={setUser} />
            }
          />
        </Routes>
      )}
    </Router>
  );
};


// COLORE DA SALVARE --> #d53534cc

export default App;
