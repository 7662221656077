import { MagnifyingGlassIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { PencilIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import {
  Card,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { useState } from "react";
import { SelectWithTitle } from "./SelectWithTitle";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_CLIENTI } from "../../Utils/routes";
import ClientModal from "./ClientModal";
import { Triangle } from "react-loader-spinner";

export function MembersTable({ elements = [], keysToShow = [], loadingClienti }) {
  const TABLE_HEAD = ["ragione_sociale", ...keysToShow, ""];
  const TABS = keysToShow.map((key) => ({
    label: key.replace("_", " ").toUpperCase(),
    value: key,
  }));
  const [selectedFilter, setSelectedFilter] = useState(TABS?.[0]);
  const [filterValue, setFilterValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigation = useNavigate();
  const buttonAddNewClient = (functionOnClick) => (
    <Button
      onClick={() => functionOnClick()}
      className="flex items-center gap-3 rounded-none bg-[#bc000ad9]"
      size="sm"
    >
      <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Aggiungi Cliente
    </Button>
  );

  const buttonEditClient = (functionOnClick) => (
    <Tooltip content="Modifica cliente">
      <IconButton
        className="rounded-none"
        variant="outlined"
        onClick={() => functionOnClick()}
        color="black"
        size="sm"
      >
        <PencilIcon className="h-4 w-4" />
      </IconButton>
    </Tooltip>
  );

  const getFilteredElements = () => {
    if (filterValue && filterValue !== "") {
      return elements?.filter((e) =>
        e?.[selectedFilter?.value]
          ?.toLowerCase()
          .includes(filterValue?.toLowerCase())
      );
    }
    return elements;
  };

  return (
    <Card className="rounded-none w-full">
      <CardBody className="p-0 pt-6 px-0">
        {!loadingClienti ? (
          <>
            <div className="mb-2 px-5 flex items-start justify-between gap-8">
              <div className="flex flex-row gap-4">
                <SelectWithTitle
                  defaultValue={selectedFilter}
                  title={"CERCA PER..."}
                  options={TABS}
                  onSelectFunc={setSelectedFilter}
                />
                <Input
                  label={"INSERISCI " + selectedFilter.label + "..."}
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  value={filterValue}
                  onChange={(e) => setFilterValue(e?.target?.value)}
                />
              </div>
              <div className="flex shrink-0 flex-col items-start gap-2 sm:flex-row">
                <ClientModal buttonToOpenModal={buttonAddNewClient} />
              </div>
            </div>
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head.toUpperCase().replace("_", " ")}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getFilteredElements()
                  ?.slice((currentPage - 1) * 20, currentPage * 20)
                  .map((element, index) => {
                    const isLast = index === elements.length - 1;
                    const classes = isLast
                      ? "p-4 border-b border-blue-gray-50"
                      : "p-4 border-b border-blue-gray-50";

                    console.info(selectedFilter, filterValue);

                    return (
                      <tr key={element?.id}>
                        <td className={"p-4 border-b border-blue-gray-50"}>
                          <div className="flex justify-start">
                            <Link
                              to={ROUTE_CLIENTI + element?.id}
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                fontWeight: "bold",
                              }}
                            >
                              {element?.["ragione_sociale"]}
                            </Link>
                          </div>
                        </td>
                        {keysToShow?.map((keyToShow) => (
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {element[keyToShow] ?? "-"}
                              </Typography>
                            </div>
                          </td>
                        ))}
                        <td className={classes + " flex gap-2 justify-end"}>
                          <Tooltip content="Dettagli cliente">
                            <IconButton
                              onClick={() =>
                                navigation(ROUTE_CLIENTI + element?.id)
                              }
                              className="rounded-none bg-[#ce000c]"
                              variant="primary"
                              size="sm"
                            >
                              <UserCircleIcon className="h-5 w-5" />
                            </IconButton>
                          </Tooltip>
                          <ClientModal
                            buttonToOpenModal={buttonEditClient}
                            client={element}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        ) : (
          <div className="py-2">

          <Triangle
            visible={true}
            height="48"
            width="48"
            color="#c62b38"
            secondaryColor="#d6888f"
            ariaLabel="rings-loading"
            wrapperStyle={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              height: "100%",
            }}
            wrapperClass=""
            />
            </div>
        )}
      </CardBody>
      {
        !loadingClienti ? (
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography variant="small" color="blue-gray" className="font-normal">
              Pagina {currentPage} di{" "}
              {getFilteredElements().length / 20 > 1
                ? Number((getFilteredElements().length / 20).toFixed(0)) + 1
                : 1}
            </Typography>
            {getFilteredElements().length / 20 > 1 ? (
              <div className="flex gap-2">
                {currentPage > 1 ? (
                  <Button
                    variant="outlined"
                    className="rounded-none"
                    style={{ borderColor: "#bc000ad9", color: "#bc000ad9" }}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    size="sm"
                  >
                    Precedente
                  </Button>
                ) : null}
                {currentPage <
                Number((getFilteredElements().length / 20).toFixed(0)) + 1 ? (
                  <Button
                    variant="outlined"
                    style={{ borderColor: "#bc000ad9", color: "#bc000ad9" }}
                    className="rounded-none"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    size="sm"
                  >
                    Successiva
                  </Button>
                ) : null}
              </div>
            ) : null}
          </CardFooter>
        ) : undefined
      }
    </Card>
  );
}
