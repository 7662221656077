import { DocumentMagnifyingGlassIcon, DocumentPlusIcon, MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  Card,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
  Chip,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { SelectWithTitle } from "./SelectWithTitle";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_CLIENTI, ROUTE_NOLLEGGI_CONTRATTI } from "../../Utils/routes";
import { PERIODI_FATTURAZIONE } from "../../Utils/consts";
import ModaleContratto from "./ModaleContratto";
import { toast } from "react-toastify";
import axios from "axios";
import ModaleEliminaElemento from "./ModaleEliminaElemento";
import { Triangle } from "react-loader-spinner";

export function ContrattiTable({ loadingContratti, elements = [], keysToShow = [] }) {
  const navigation = useNavigate();
  const [clienti, setClienti] = useState([]);

  const getClienti = async() => {
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/clienti`)
      .then((response) => {
        setClienti(response?.data);
      });
    }

  useEffect(() =>  {
    getClienti();
  },[]);

  const TABLE_HEAD = [
    "Cliente",
    "Tipo Pacchetto",
    ...keysToShow,
    "Periodo Fatturazione",
    "",
  ];
  const TABS = keysToShow.map((key) => ({
    label: key.replace("_", " ").toUpperCase(),
    value: key,
  }));

  const [selectedFilter, setSelectedFilter] = useState(TABS?.[0]);
  const [filterValue, setFilterValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getFilteredElements = () => {
    if (filterValue && filterValue !== "") {
      return elements?.filter((e) =>
        e?.[selectedFilter?.value]
          ?.toLowerCase()
          .includes(filterValue?.toLowerCase())
      );
    }
    return elements;
  };

  const buttonAddNewContratto = (functionOnClick) => (
    <Button
      onClick={() => functionOnClick()}
      className="flex items-center gap-3 rounded-none bg-[#bc000ad9]"
      size="sm"
    >
      <DocumentPlusIcon strokeWidth={2} className="h-4 w-4" /> Aggiungi
      Contratto
    </Button>
  );

  const eliminaContratto = async (idContratto) => {
    return await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/contratti/noleggi/${idContratto}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        toast.success("Contratto eliminato con successo!", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Errore durante l'eliminazione del contratto!", {
          position: "top-center",
        });
      });
  };

  return (
    <Card className="h-full w-full rounded-none">
      <CardBody className="p-0 pt-6 overflow-auto px-0">
        {loadingContratti ? (
          <Triangle
            visible={true}
            height="48"
            width="48"
            color="#c62b38"
            secondaryColor="#d6888f"
            ariaLabel="rings-loading"
            wrapperStyle={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              height: "100%",
            }}
            wrapperClass=""
          />
        ) : (
          <>
            <div className="mb-2 px-5 flex items-start justify-between gap-8">
              <div className="flex flex-row gap-4">
                <SelectWithTitle
                  defaultValue={selectedFilter ?? null}
                  title={"CERCA PER..."}
                  options={TABS}
                  onSelectFunc={setSelectedFilter}
                />
                <Input
                  label={"INSERISCI " + selectedFilter?.label + "..."}
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  value={filterValue}
                  onChange={(e) => setFilterValue(e?.target?.value)}
                />
              </div>
              <div className="flex shrink-0 flex-col items-start gap-2 sm:flex-row">
                <ModaleContratto buttonToOpenModal={buttonAddNewContratto} />
              </div>
            </div>
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head.toUpperCase().replace("_", " ")}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getFilteredElements()
                  ?.slice((currentPage - 1) * 20, currentPage * 20)
                  .map((element, index) => {
                    const isLast = index === elements.length - 1;
                    const classes = isLast
                      ? "p-4 border-b border-blue-gray-50"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={element?.id}>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Link
                              to={ROUTE_CLIENTI + element?.["id_cliente"]}
                              variant="small"
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                                fontWeight: "bold",
                              }}
                            >
                              {clienti.find(
                                (e) => e.id === element?.["id_cliente"]
                              )?.["ragione_sociale"] ?? "-"}
                            </Link>
                          </div>
                        </td>
                        <td className={classes}>
                          <Chip
                            style={{ width: "max-content" }}
                            color={
                              element["fogli_base_b_w"] ||
                              element["fogli_base_colore"] !== 0
                                ? "green"
                                : "blue"
                            }
                            size="sm"
                            variant="ghost"
                            value={
                              element["fogli_base_b_w"] ||
                              element["fogli_base_colore"] !== 0
                                ? "Canone"
                                : "A consumo"
                            }
                          />
                        </td>
                        {keysToShow?.map((keyToShow) => (
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {element[keyToShow] ?? "-"}
                              </Typography>
                            </div>
                          </td>
                        ))}
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {PERIODI_FATTURAZIONE[
                                element["periodo_fatturazione"]
                              ] ??
                                "Ogni " +
                                  element["periodo_fatturazione"] +
                                  " mesi"}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes + " flex gap-2 justify-end"}>
                          <Tooltip content="Dettagli contratto">
                            <IconButton
                              onClick={() =>
                                navigation(
                                  ROUTE_NOLLEGGI_CONTRATTI + element?.id
                                )
                              }
                              className="rounded-none bg-[#bc000ad9]"
                              variant="primary"
                              size="sm"
                            >
                              <DocumentMagnifyingGlassIcon className="h-5 w-5" />
                            </IconButton>
                          </Tooltip>
                          <ModaleEliminaElemento
                            functionForDelete={() =>
                              eliminaContratto(element?.id)
                            }
                            buttonToOpenModal={(functionOnClick) => {
                              return (
                                <Tooltip content="Elimina contratto">
                                  <IconButton
                                    className="rounded-none"
                                    variant="outlined"
                                    onClick={() => functionOnClick()}
                                    color="black"
                                    size="sm"
                                  >
                                    <TrashIcon className="h-4 w-4" />
                                  </IconButton>
                                </Tooltip>
                              );
                            }}
                          />
                          <ModaleContratto
                            contract={element}
                            buttonToOpenModal={(functionOnClick) => {
                              return (
                                <Tooltip content="Modifica contratto">
                                  <IconButton
                                    className="rounded-none"
                                    variant="outlined"
                                    onClick={() => functionOnClick()}
                                    color="black"
                                    size="sm"
                                  >
                                    <PencilIcon className="h-4 w-4" />
                                  </IconButton>
                                </Tooltip>
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
      </CardBody>
        {loadingContratti ? undefined : (
        
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Pagina {currentPage} di{" "}
              {getFilteredElements().length / 20 > 1
                ? Number((getFilteredElements().length / 20).toFixed(0)) + 1
                : 1}
            </Typography>
            {getFilteredElements().length / 20 > 1 ? (
              <div className="flex gap-2">
                {currentPage > 1 ? (
                  <Button
                    variant="outlined"
                    className="rounded-none"
                    style={{ borderColor: "#bc000ad9", color: "#bc000ad9" }}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    size="sm"
                  >
                    Precedente
                  </Button>
                ) : null}
                {currentPage <
                Number((getFilteredElements().length / 20).toFixed(0)) + 1 ? (
                  <Button
                    variant="outlined"
                    className="rounded-none"
                    style={{ borderColor: "#bc000ad9", color: "#bc000ad9" }}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    size="sm"
                  >
                    Successiva
                  </Button>
                ) : null}
              </div>
            ) : null}
          </CardFooter>
        )}
    </Card>
  );
}
