import { Typography } from "@material-tailwind/react";

const CampoBoxGrande = ({
    titoloCampo,
    valoreCampo,
    tipo = 'text',
    coloreTitolo = 'gray',
    coloreCampo = 'gray',
    ...props
}) => {
    return (
      <div className="flex gap-2 w-full">
        <div className="w-[40%]">
          <Typography
            color={coloreTitolo}
            variant="paragraph"
            style={{ fontWeight: "bold" }}
          >
            {titoloCampo}:
          </Typography>
        </div>
        <div className="w-[60%]"> 
        {
          tipo === 'text' ? (
            <Typography color={coloreCampo} variant="paragraph">
              {valoreCampo}
            </Typography>
          ) : undefined
        }
        {props.children}
        </div>
      </div>
    );
}

export default CampoBoxGrande;