import { Breadcrumbs } from "@material-tailwind/react";
import { ROUTE_HOME } from "../../Utils/routes";

export function BreadcrumbsWithIcon({ breadcrumbElements = [] }) {
  return (
    <Breadcrumbs style={{ backgroundColor: 'transparent', padding: 0, marginTop: '1rem', marginBottom: '1rem' }}>
      <a href={ROUTE_HOME} className="opacity-60">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      </a>
      {
        breadcrumbElements.map(({label, link}, index) => {
          return breadcrumbElements.lastIndexOf(index) ? (
            <p className="opacity-60 text-[gray]" style={{ textDecoration: 'none', cursor: 'default'}}> {label} </p>
          ) : (
            <a key={index} href={link} className="opacity-60">
              <span>{label}</span>
            </a>
          );
        })
      }
    </Breadcrumbs>
  );
}
