import React from "react";
import { Select, Option } from "@material-tailwind/react";

export function SelectWithTitle({
  defaultValue,
  options = [],
  title = "Title",
  onSelectFunc = () => {
    console.info("click");
  },
  width = "w-72",
}) {
  return (
    <div className={width}>
      <Select
        size="lg"
        label={title ?? defaultValue}
        value={options?.find((e) => e.value === defaultValue)?.label}
      >
        {options.map(({ label, value, product = null }) => (
          <Option
            key={label ?? ""}
            value={defaultValue?.value}
            onClick={() => onSelectFunc({ label, value, product })}
            className="flex items-center gap-2"
          >
            {label}
          </Option>
        ))}
      </Select>
    </div>
  );
}
