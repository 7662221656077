import { Card, CardBody, Typography } from "@material-tailwind/react";
import { MACCHINA_A_COLORI } from "../../../Utils/consts";
import CampoBoxGrande from "../../../components/wrapper/CampoBoxGrande";
import TitoloBoxGrande from "../../../components/wrapper/TitoloBoxGrande";
import Prezzo from "../../../components/wrapper/Prezzo";
import Divisore from "../../../components/atoms/Divisore";

const LetturaMacchina = ({
    indexMap, 
    macchina, 
    fattura,
    ultimaLetturaMacchina, 
    penultimaLetturaMacchina,
    numPagBiancoNeroUltimaLettura,
    numPagColoriUltimaLettura,
    numPagBiancoNeroPenultimaLettura,
    numPagColoriPenultimaLettura,
    pagine_incluse_nel_canone_b_n,
    pagine_incluse_nel_canone_col,
    macchine_totali,
    withIva
}) => {
    // BIANCO E NERO
    const prezzoEccedenzaBiancoNero = fattura?.["prezzo_bw"] ?? 0;
    const eccedenzaBiancoNero =
      (numPagBiancoNeroUltimaLettura ?? 0 - numPagBiancoNeroPenultimaLettura ?? 0) - (pagine_incluse_nel_canone_b_n / macchine_totali );
    const costoEccedenzaBiancoNero = (eccedenzaBiancoNero * prezzoEccedenzaBiancoNero) ?? 0;
    const ivaEccedenzaBiancoNero = withIva ? (costoEccedenzaBiancoNero * 0.22) ?? 0 : 0;
    const totaleBiancoNero = (
                  costoEccedenzaBiancoNero +
                  ivaEccedenzaBiancoNero
                ) ?? 0;

    // A COLORI
    const prezzoEccedenzaColori = fattura?.["prezzo_col"] ?? 0;
    const eccedenzaColori = (numPagColoriUltimaLettura ?? 0 - numPagColoriPenultimaLettura ?? 0) - (pagine_incluse_nel_canone_col / macchine_totali);
    const costoEccedenzaColori = eccedenzaColori * prezzoEccedenzaColori;
    const ivaEccedenzaColori = withIva ? (costoEccedenzaColori * 0.22) ?? 0 : 0;
    const totaleColori = (costoEccedenzaColori + ivaEccedenzaColori);
    const numeroDiSerieMacchina = macchina?.["numero_di_serie"]

    // TOTALE
    const totaleMacchina = (totaleBiancoNero + totaleColori) ?? 0;

    return (
      <Card className="rounded-none my-3 da-stampare break-after-page">
        <CardBody>
          <TitoloBoxGrande
            titolo={`Macchina ${indexMap + 1} (${numeroDiSerieMacchina})`}
          />

          <CampoBoxGrande
            titoloCampo={"Data ultima lettura"}
            valoreCampo={ultimaLetturaMacchina?.["data_lettura"]}
          />

          <CampoBoxGrande
            titoloCampo={"Data penultima lettura:"}
            valoreCampo={
              penultimaLetturaMacchina?.["data_lettura"] ?? "Assente"
            }
          />

          <Divisore />

          <CampoBoxGrande
            titoloCampo={"Pag. B/N ultima lettura:"}
            valoreCampo={numPagBiancoNeroUltimaLettura}
          />

          <CampoBoxGrande
            titoloCampo={"Pag. B/N penultima lettura:"}
            valoreCampo={numPagBiancoNeroPenultimaLettura}
          />

          <CampoBoxGrande
            titoloCampo={"Eccedenza pagine B/N (non incluse nel canone)"}
            valoreCampo={eccedenzaBiancoNero}
          />

          <CampoBoxGrande titoloCampo={"Costo eccedenza"} tipo="custom">
            <Prezzo
              color="orange"
              variant="lead"
              valore={costoEccedenzaBiancoNero}
              appendNotes={`( ${eccedenzaBiancoNero} pag. * €${prezzoEccedenzaBiancoNero.toFixed(
                2
              )} )`}
            />
          </CampoBoxGrande>

          <CampoBoxGrande titoloCampo={"IVA"} tipo="custom">
            <Prezzo
              color="orange"
              variant="lead"
              valore={ivaEccedenzaBiancoNero}
            />
          </CampoBoxGrande>

          <CampoBoxGrande titoloCampo={"Totale B/N"} tipo="custom">
            <Prezzo color="green" variant="lead" valore={totaleBiancoNero} />
          </CampoBoxGrande>

          {macchina?.tipologia_prodotto === MACCHINA_A_COLORI.value ? (
            <>
              <Divisore />

              <CampoBoxGrande
                titoloCampo={"Pag. a colori ultima lettura"}
                valoreCampo={numPagColoriUltimaLettura}
              />

              <CampoBoxGrande
                titoloCampo={"Pag. a colori penultima lettura"}
                valoreCampo={numPagColoriPenultimaLettura}
              />

              <CampoBoxGrande
                titoloCampo={
                  "Eccedenza pagine a colori (non incluse nel canone)"
                }
                valoreCampo={eccedenzaColori}
              />

              <CampoBoxGrande titoloCampo={"Costo eccedenza"} tipo="custom">
                <Prezzo
                  color="orange"
                  variant="lead"
                  valore={costoEccedenzaColori}
                  appendNotes={`( ${eccedenzaColori}pag. * €${prezzoEccedenzaColori.toFixed(
                    2
                  )} )`}
                />
              </CampoBoxGrande>

              <CampoBoxGrande titoloCampo={"IVA"} tipo="custom">
                <Prezzo
                  color="orange"
                  variant="lead"
                  valore={ivaEccedenzaColori}
                />
              </CampoBoxGrande>

              <CampoBoxGrande titoloCampo={"IVA"} tipo="custom">
                <Prezzo
                  color="orange"
                  variant="lead"
                  valore={ivaEccedenzaColori}
                />
              </CampoBoxGrande>

              <CampoBoxGrande titoloCampo={"Totale a colori"} tipo="custom">
                <Prezzo color="green" variant="lead" valore={totaleColori} />
              </CampoBoxGrande>
            </>
          ) : undefined}
          <Divisore />

          <CampoBoxGrande titoloCampo={"Totale macchina"} tipo="custom">
            <Prezzo color="green" variant="lead" valore={totaleMacchina} />
          </CampoBoxGrande>
        </CardBody>
      </Card>
    );
}

export default LetturaMacchina;