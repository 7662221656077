import { useEffect, useState } from "react";
import { ROUTE_HOME } from "./Utils/routes";
import { Triangle } from "react-loader-spinner";
import { Input } from "@material-tailwind/react";
import axios from "axios";

const Login = ({user, setUser}) => {
    const [loading, setLoading] = useState(!localStorage.getItem("user"));
    const [username, setUsername] = useState(null);
    const [password, setPassowrd] = useState(null);

    const login = async () => {
      setLoading(true);
      return await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/login`, { username, password }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        localStorage.setItem("user", JSON.stringify(result.data));
        
        setTimeout(() => {
          setUser(result.data);
           window.location.href = ROUTE_HOME;
         }, 2000);
      })
      .catch((error) => {
        alert(error)
      });
    }

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <div className="d-flex items-center h-[100vh] justify-center">
      {loading ? (
        <Triangle
          visible={true}
          height="72"
          width="72"
          color="#c62b38"
          secondaryColor="#d6888f"
          ariaLabel="rings-loading"
          wrapperStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
          wrapperClass=""
        />
      ) : (
        <div className="background h-[100vh] flex items-center justify-center">
          <div className="boxLogin flex flex-col gap-2 p-4 min-w-[20vw]">
            <div className="mb-2 flex items-center justify-center gap-4 p-4">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/e/e1/Olivetti_logo_2006.png"
                alt="brand"
                className="h-14"
              />
            </div>

            <Input
              label={"USERNAME..."}
              value={username}
              onChange={(e) => setUsername(e.target?.value)}
            />
            <Input
              label={"PASSWORD..."}
              type="password"
              value={password}
              onChange={(e) => setPassowrd(e.target?.value)}
            />
            <button
              className="bg-red-300 my-8 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                login();
              }}
            >
              LOGIN
            </button>
          </div>
        </div>
      )}
    </div>
  );

}

export default Login;