import { Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { SelectWithTitle } from "./SelectWithTitle";
import axios from "axios";
import { toast } from "react-toastify";


export default function ModalePreventivo({
  buttonToOpenModal,
  contratto,
  preventivo = null,
  prodotti = [],
}) {
  const [showModal, setShowModal] = React.useState(false);
  const idProdotti = prodotti?.map((prodotto) => prodotto.id);

  const defaultData = preventivo ?? {
    id_prodotti: JSON.stringify(idProdotti),
    tipo_fattura: 0,
    pagato: false,
    id_contratto: contratto?.id,
    with_trash_costs: false,
    trashCosts: 0.0,
    cauzione: 0.0,
    prezzo_totale: 0,
    pagine_extra_bianco_e_nero: 0,
    pagine_extra_a_colori: 0,
    prezzo_bw: contratto?.prezzo_bw,
    prezzo_base: contratto?.prezzo_base,
    prezzo_col: contratto?.prezzo_col,
    mesi_di_fatturazione: contratto?.periodo_fatturazione,
    data_emissione: new Date().toLocaleDateString("it-IT"),
    data_pagamento: null,
    mese_fatturazione: contratto?.prossima_fatturazione,
    with_iva: false,
  };

  const [dataPreventivo, setDataPreventivo] = useState(defaultData);
  const [withTrashCosts, setWithTrashCosts] = useState(defaultData?.with_trash_costs ?? false);
  const [withCauzione, setWithCauzione] = useState(false);
  const [withIva, setWithIva] = useState(false);
  const [lettureProdotti, setLettureProdotti] = useState([]);

  const setFormData = (key, value) => {
    setDataPreventivo({
      ...dataPreventivo,
      [key]: value,
    });
  };

  const [lettureSelezioneMacchine, setLettureSelezioneMacchine] = useState([]);
  const [penultimeLettureMacchine, setPenultimeLettureMacchine] = useState([]);

  const [letturaMacchinaSelezionata, setLetturaMacchinaSelezionata] = useState(null);
  const [penultimaLetturaMacchinaSelezionata, setPenultimaLetturaMacchinaSelezionata] =
    useState(null);
  
  const creaPreventivo = () => {
    let pagine_totali_consumate_per_questo_preventivo_b_n = 0;
    let pagine_totali_consumate_per_questo_preventivo_col = 0;
    
    Object.keys(lettureSelezioneMacchine).forEach((product) => {
       if(product !== undefined) {
         pagine_totali_consumate_per_questo_preventivo_b_n +=
           (lettureSelezioneMacchine[product]?.pagine_b_n ?? 0) -  
           (penultimeLettureMacchine[product]?.pagine_b_n ?? 0);
         pagine_totali_consumate_per_questo_preventivo_col +=
           (lettureSelezioneMacchine[product]?.pagine_col ?? 0) -
           (penultimeLettureMacchine[product]?.pagine_col ?? 0);
       }
    })

    pagine_totali_consumate_per_questo_preventivo_b_n -=
      ((contratto?.fogli_base_b_w * dataPreventivo?.mesi_di_fatturazione) ?? 0);

    pagine_totali_consumate_per_questo_preventivo_col -=
      ((contratto?.fogli_base_col * dataPreventivo?.mesi_di_fatturazione) ?? 0);

    if(pagine_totali_consumate_per_questo_preventivo_b_n < 0) {
        pagine_totali_consumate_per_questo_preventivo_b_n = 0;
    }

    if(pagine_totali_consumate_per_questo_preventivo_col < 0) {
        pagine_totali_consumate_per_questo_preventivo_col = 0;
    }

    let prezzo_b_n = pagine_totali_consumate_per_questo_preventivo_b_n *
          dataPreventivo?.prezzo_bw;

    let prezzo_col = pagine_totali_consumate_per_questo_preventivo_col *
          dataPreventivo?.prezzo_col;
    
    let prezzo_base = dataPreventivo?.prezzo_base;

    let trashcosts = parseFloat(dataPreventivo?.trashCosts);
    
    // CALCOLO IVA E AGGIUNGO
    prezzo_base = prezzo_base * defaultData?.mesi_di_fatturazione;
    
    if(withIva) {
      prezzo_b_n += prezzo_b_n * 0.22;
      prezzo_col += prezzo_col * 0.22;
      prezzo_base += prezzo_base * 0.22;
      trashcosts += trashcosts * 0.22;
    }

    console.info(
      prezzo_b_n,
        prezzo_col,
        prezzo_base,
        trashcosts,
        parseFloat(dataPreventivo["cauzione"] ?? 0)
    );

    debugger;


    const preventivoToSent = {
      ...dataPreventivo,
      prezzo_totale:
        prezzo_b_n +
        prezzo_col +
        prezzo_base +
        trashcosts +
        parseFloat(dataPreventivo["cauzione"] ?? 0),
      pagine_extra_bianco_e_nero:
        pagine_totali_consumate_per_questo_preventivo_b_n,
      pagine_extra_a_colori: pagine_totali_consumate_per_questo_preventivo_col,
      penultime_letture: JSON.stringify(penultimeLettureMacchine),
      ultime_letture: JSON.stringify(lettureSelezioneMacchine),
    };

    if(withTrashCosts) {
      preventivoToSent['trashCosts'] = parseFloat(dataPreventivo['trashCosts']) ?? null;
    }

    if (withCauzione) {
      preventivoToSent["cauzione"] =
        parseFloat(dataPreventivo['cauzione']) ?? null;
    }

    preventivoToSent["with_iva"] = withIva;

    salvaPreventivo(preventivoToSent);

    setShowModal(false);
    setDataPreventivo(defaultData);
    setWithTrashCosts(false);
    setWithCauzione(false);
    setWithIva(false);
    setLettureSelezioneMacchine([]);
    setPenultimeLettureMacchine([]);
    setLetturaMacchinaSelezionata(null);
    setPenultimaLetturaMacchinaSelezionata(null);
  }

  const salvaPreventivo = async (postData) => {
    if (preventivo) {
      return await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/preventivi/${preventivo?.id}`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((result) => {
          toast.success("Preventivo modificato con successo!", {
            position: "top-center",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          toast.error("Errore durante la creazione  del preventivo!", {
            position: "top-center",
          });
        });
    }
    return await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/preventivi/noleggi`, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        toast.success("Preventivo salvato con successo!", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Errore durante il salvataggio del preventivo!", {
          position: "top-center",
        });
      });
  };

  const getLettureProdotti = async (idProdotto) => {
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/letture_prodotto`)
      .then((letture) => {
        let lettureProdottiTmp = []; 
        idProdotti?.forEach((prodotto) => {
          lettureProdottiTmp = {
            ...lettureProdottiTmp,
            [prodotto]: letture?.data?.filter((lettura) => lettura.id_prodotto === prodotto)
          };
        });
    
        setLettureProdotti(lettureProdottiTmp);
      });

  };
  
  useEffect(() => {
    getLettureProdotti()
  }, []);
  
  useEffect(() => {
      setFormData("with_trash_costs", withTrashCosts?.value);
  }, [withTrashCosts]);

  useEffect(() => {
    setFormData("with_cauzione", withCauzione?.value);
  }, [withCauzione]);

  useEffect(() => {
    setFormData("with_iva", withIva);
    console.info(dataPreventivo);
  }, [withIva]);

  useEffect(() => {
    const letture = lettureProdotti?.[
      letturaMacchinaSelezionata?.product
    ]?.filter((lettura) => lettura.id === letturaMacchinaSelezionata.value);

    setLettureSelezioneMacchine({
        ...lettureSelezioneMacchine,
        [letturaMacchinaSelezionata?.product]: letture?.map((lettura) => {
            return {
              pagine_b_n: lettura.numero_pagine_b_n ?? 0,
              pagine_col: lettura.numero_pagine_col ?? 0,
              data_lettura: lettura.data_lettura ?? null,
            };
        })[0],
    });
  }, [letturaMacchinaSelezionata]);

  useEffect(() => {
    const letture = lettureProdotti?.[
      penultimaLetturaMacchinaSelezionata?.product
    ]?.filter(
      (lettura) => lettura.id === penultimaLetturaMacchinaSelezionata.value
    );
    setPenultimeLettureMacchine({
      ...penultimeLettureMacchine,
      [penultimaLetturaMacchinaSelezionata?.product]: letture?.map((lettura) => {
        return {
          pagine_b_n: lettura.numero_pagine_b_n ?? 0,
          pagine_col: lettura.numero_pagine_col ?? 0,
          data_lettura: lettura.data_lettura ?? null,
        };
      })[0],
    });
  }, [penultimaLetturaMacchinaSelezionata]);

  return (
    <>
      {buttonToOpenModal(() => setShowModal(!showModal))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-2 border-red-300 shadow-lg min-w-[30vw] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {"NUOVO PREVENTIVO FATTURA"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div className="relative p-6 flex flex-col gap-4">
                  <h3 className="sectionTitleModal">INFORMAZIONI</h3>
                  <Input
                    label={"DATA FATTURAZIONE"}
                    value={dataPreventivo["mese_fatturazione"]}
                  />
                  <Input
                    label={"DATA EMISSIONE..."}
                    value={dataPreventivo["data_emissione"]}
                  />
                  <h3 className="sectionTitleModal">AGGIUNTIVI</h3>
                  <SelectWithTitle
                    width="w-full"
                    title="AGGIUNGERE COSTI SMALTIMENTO?"
                    options={[
                      {
                        label: "Si",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                    onSelectFunc={setWithTrashCosts}
                  />
                  {dataPreventivo?.with_trash_costs ? (
                    <Input
                      label={"COSTO SMALTIMENTO..."}
                      value={dataPreventivo["trashCosts"]}
                      onChange={(e) => {
                        setFormData("trashCosts", e?.target?.value);
                      }}
                    />
                  ) : undefined}
                  <SelectWithTitle
                    width="w-full"
                    title="AGGIUNGERE IVA?"
                    options={[
                      {
                        label: "Si",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                    onSelectFunc={(e) => {
                        setWithIva(e?.value);
                        console.info(withIva);
                      }
                    }
                  />
                  <SelectWithTitle
                    width="w-full"
                    title="AGGIUNGERE CAUZIONE?"
                    options={[
                      {
                        label: "Si",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                    onSelectFunc={setWithCauzione}
                  />
                  {dataPreventivo?.with_cauzione ? (
                    <Input
                      label={"CAUZIONE..."}
                      value={dataPreventivo["cauzione"]}
                      onChange={(e) =>
                        setFormData("cauzione", e?.target?.value)
                      }
                    />
                  ) : undefined}
                  <h3 className="sectionTitleModal">MACCHINE</h3>
                  {prodotti?.map((product) => {
                    const lettureProduct = lettureProdotti[product.id];
                    const lettureOptions = lettureProduct?.map((lettura) => {
                      return {
                        label: `Lettura del ${lettura.data_lettura}`,
                        value: lettura.id,
                        product: product.id,
                      };
                    });

                    return (
                      <>
                        <p className="modalSubtitle">{`MACCHINA ${product.numero_di_serie}`}</p>
                        <SelectWithTitle
                          width="w-full"
                          title={"ULTIMA LETTURA"}
                          options={lettureOptions}
                          onSelectFunc={setLetturaMacchinaSelezionata}
                        />
                        {lettureOptions.length > 1 ? (
                          <SelectWithTitle
                            width="w-full"
                            title={"PENULTIMA LETTURA"}
                            options={lettureOptions}
                            onSelectFunc={
                              setPenultimaLetturaMacchinaSelezionata
                            }
                          />
                        ) : undefined}
                      </>
                    );
                  })}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setDataPreventivo(defaultData);
                      setWithTrashCosts(false);
                      setWithCauzione(false);
                      setWithIva(false);
                      setLettureSelezioneMacchine([]);
                      setPenultimeLettureMacchine([]);
                      setLetturaMacchinaSelezionata(null);
                      setPenultimaLetturaMacchinaSelezionata(null);
                    }}
                  >
                    ANNULLA
                  </button>
                  <button
                    className="bg-red-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => creaPreventivo()}
                  >
                    {"CREA PREVENTIVO"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
