/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import {
  Link,
  useParams,
} from "react-router-dom";
import {
  DocumentChartBarIcon,
  PencilIcon,
  PresentationChartBarIcon,
  PrinterIcon,
  ScaleIcon,
  SwatchIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ROUTE_NOLLEGGI_CONTRATTI } from "../../Utils/routes";
import { CATEGORIE_PRODOTTI, MACCHINA_A_COLORI, TIPOLOGIE_PRODOTTI } from "../../Utils/consts";
import ModaleLettura from "../../components/wrapper/ModaleLettura";
import ModaleEliminaElemento from "../../components/wrapper/ModaleEliminaElemento";
import axios from "axios";
import { toast } from "react-toastify";
import { Triangle } from "react-loader-spinner";

const Prodotto = function () {
  const { idProdotto } = useParams();
  const [prodotto, setProdotto] = useState(null);
  const [loadingProdotto, setLoadingProdotto] = useState(true);
  const [lettureProdotto, setLettureProdotto] = useState(null);
  const [loadingLettureProdotto, setLoadingLettureProdotto] = useState(true);

  const [TABLE_COLUMNS, setTableColumns] = useState([
        "data_lettura",
        "pagine_totali",
        "pagine_b&n",
        "",
    ]);

    useEffect(() => {
      getProdotto();
      }, []);

    useEffect(() => {
    }, [lettureProdotto]);

    const getLettureProdotto = async () => {
      setLoadingLettureProdotto(true);
      return await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/letture_prodotto/prodotto/${idProdotto}`
        ).then((response) => {
          setLettureProdotto(response?.data);
          setTimeout(() => setLoadingLettureProdotto(false), 2000);
        }); 
    }

    const getProdotto = async () => {
      setLoadingLettureProdotto(true);
      return await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/prodotti/${idProdotto}`
        )
        .then((response) => {
          setProdotto(response?.data);
          if(response.data?.['tipologia_prodotto'] === MACCHINA_A_COLORI.value) {
            setTableColumns([
              "data_lettura",
              "pagine_totali",
              "pagine_b&n",
              "pagine_colori",
              "",
            ]);
          }
          getLettureProdotto();
          setTimeout(() => setLoadingProdotto(false), 2000);
        });
    };

    const eliminaLettura = async (idLettura) => {
    return await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/letture_prodotto/${idLettura}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        toast.success("Lettura eliminata con successo!", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        toast.error("Errore durante l'eliminazione della lettura!", {
          position: "top-center",
        });
      });
  };

    const buttonAddNewLettura = (functionOnClick) => (
      <Button
        onClick={() => functionOnClick()}
        className="flex items-center gap-3 rounded-none bg-[#bc000ad9]"
        size="sm"
      >
        <PrinterIcon strokeWidth={2} className="h-4 w-4" /> Aggiungi Lettura
      </Button>
    );

    const buttonEditLettura = (functionOnClick) => (
      <Tooltip content="Modifica lettura">
        <IconButton
          className="rounded-none"
          variant="outlined"
          onClick={() => functionOnClick()}
          color="black"
          size="sm"
        >
          <PencilIcon className="h-4 w-4" />
        </IconButton>
      </Tooltip>
    );

    const buttonDeleteLettura = (functionOnClick) => (
      <Tooltip content="Elimina lettura">
        <IconButton
          className="rounded-none"
          variant="outlined"
          onClick={() => functionOnClick()}
          color="red"
          size="sm"
        >
          <TrashIcon className="h-4 w-4" />
        </IconButton>
      </Tooltip>
    );

  return (
    <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
      <div>
        <Typography className="my-2 mb-4" color="gray" variant="h5">
          PRODOTTO (#{prodotto?.id})
        </Typography>
      </div>

      <div className="flex gap-2 w-full mb-4">
        <Card className="w-full h-fit rounded-none">
          <CardBody>
            {loadingLettureProdotto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : (
              <div className="flex gap-2 items-center">
                <DocumentChartBarIcon className="h-10 w-10 text-[green]" />
                <div>
                  <Typography color="gray" variant="h5">
                    {(lettureProdotto &&
                      lettureProdotto[0]?.["numero_pagine_totali"]) ??
                      0}
                  </Typography>
                  <Typography color="gray" variant="small">
                    PAGINE TOTALI
                  </Typography>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
        {prodotto?.["tipologia_prodotto"] === MACCHINA_A_COLORI.value ? (
          <Card className="w-full h-fit rounded-none">
            <CardBody>
              {loadingLettureProdotto ? (
                <Triangle
                  visible={true}
                  height="48"
                  width="48"
                  color="#c62b38"
                  secondaryColor="#d6888f"
                  ariaLabel="rings-loading"
                  wrapperStyle={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                <div className="flex gap-2 items-center">
                  <SwatchIcon className="h-10 w-10 text-[#913030]" />
                  <div>
                    <Typography color="gray" variant="h5">
                      {(lettureProdotto &&
                        lettureProdotto[0]?.["numero_pagine_col"]) ??
                        0}
                    </Typography>
                    <Typography color="gray" variant="small">
                      A COLORI
                    </Typography>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        ) : undefined}
        <Card className="w-full h-fit  rounded-none">
          <CardBody>
            {loadingLettureProdotto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : (
              <div className="flex gap-2 items-center">
                <ScaleIcon className="h-10 w-10" />
                <div>
                  <Typography color="gray" variant="h5">
                    {(lettureProdotto &&
                      lettureProdotto[0]?.["numero_pagine_b_n"]) ??
                      0}
                  </Typography>
                  <Typography color="gray" variant="small">
                    BIANCO & NERO
                  </Typography>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
        <Card className="w-full h-fit rounded-none">
          <CardBody>
            {loadingLettureProdotto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : (
              <div className="flex gap-2">
                <PresentationChartBarIcon className="h-10 w-10 text-[orange]" />
                <div>
                  <Typography color="gray" variant="h5">
                    {(lettureProdotto &&
                      lettureProdotto[0]?.["data_lettura"]) ??
                      "-"}
                  </Typography>
                  <Typography color="gray" variant="small">
                    ULTIMA LETTURA
                  </Typography>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="flex gap-2 h-fit">
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingProdotto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  INFORMAZIONI
                </Typography>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Contratto:</b>
                  </Typography>
                  <Link
                    to={ROUTE_NOLLEGGI_CONTRATTI + prodotto?.["id_contratto"]}
                    variant="small"
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      fontWeight: "bold",
                    }}
                  >
                    {"# " + prodotto?.["id_contratto"]}
                  </Link>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Numero di serie:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {prodotto?.["numero_di_serie"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Categoria prodotto:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {CATEGORIE_PRODOTTI[prodotto?.["tipo_prodotto"]]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Tipologia prodotto:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {TIPOLOGIE_PRODOTTI[prodotto?.["tipologia_prodotto"]]}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="w-full h-auto rounded-none">
          <CardBody>
            {loadingLettureProdotto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  POSIZIONE
                </Typography>

                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Indirizzo:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {prodotto?.["indirizzo_prodotto"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Città:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {prodotto?.["citta_prodotto"]}
                  </Typography>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <Typography
                    variant="paragraph"
                    color="black"
                    style={{ fontWeight: "bold" }}
                  >
                    <b>Provincia:</b>
                  </Typography>
                  <Typography variant="paragraph" color="black">
                    {prodotto?.["provincia_prodotto"]}
                  </Typography>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
      <Card className="mt-4 rounded-none">
        <CardBody>
          <div className="flex justify-between items-center">
            {!loadingLettureProdotto ? (
              <>
                <Typography className="mb-2" color="gray" variant="h5">
                  LETTURE
                </Typography>
                <ModaleLettura
                  buttonToOpenModal={buttonAddNewLettura}
                  prodotto={prodotto}
                />
              </>
            ) : undefined}
          </div>
          <div>
            {loadingLettureProdotto ? (
              <Triangle
                visible={true}
                height="48"
                width="48"
                color="#c62b38"
                secondaryColor="#d6888f"
                ariaLabel="rings-loading"
                wrapperStyle={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
                wrapperClass=""
              />
            ) : lettureProdotto?.length > 0 ? (
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_COLUMNS.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head.toUpperCase().replace("_", " ")}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {lettureProdotto.map((element, index) => {
                    const isLast = index === lettureProdotto?.length - 1;
                    const classes = isLast
                      ? "p-4 border-b border-blue-gray-50"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={element?.id}>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {element?.["data_lettura"]}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {element?.["numero_pagine_totali"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {element?.["numero_pagine_b_n"]}
                          </Typography>
                        </td>
                        {prodotto?.["tipologia_prodotto"] ===
                        MACCHINA_A_COLORI.value ? (
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {element?.["numero_pagine_col"]}
                            </Typography>
                          </td>
                        ) : undefined}
                        <td className={classes + " flex gap-2 justify-end"}>
                          <ModaleEliminaElemento
                            functionForDelete={() => eliminaLettura(element.id)}
                            buttonToOpenModal={buttonDeleteLettura}
                          />
                          <ModaleLettura
                            prodotto={prodotto}
                            lettura={element}
                            buttonToOpenModal={buttonEditLettura}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <Typography className="mb-2" color="gray" variant="paragraph">
                Ancora nessuna lettura registrata per questo prodotto!
              </Typography>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Prodotto;
