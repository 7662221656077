import React, { useEffect, useState } from "react";
import { MembersTable } from "../components/wrapper/MembersTable";
import { Typography } from "@material-tailwind/react";
import { BreadcrumbsWithIcon } from "../components/atoms/Breadcrumbs";
import axios from "axios";

const Clienti = function () {
    const [clienti, setClienti] = useState([]);
    const [loadingClienti, setLoadingClienti] = useState(true);

    const getClienti = async() => {
    setLoadingClienti(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/clienti`)
      .then((response) => {
        setClienti(response?.data);
        setTimeout(() => setLoadingClienti(false), 2000);
      });
  }

  useEffect(() => {
    getClienti();
  },[]);

    return (
      <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
        <div>
          <h1 className="text-3xl font-bold">
            <Typography className="my-2" variant="h4" color="black">
              Clienti
            </Typography>
          </h1>
          <BreadcrumbsWithIcon 
            breadcrumbElements={[
              {
                label: "CLIENTI",
              }
            ]} 
          />
        </div>
        <MembersTable 
          loadingClienti={loadingClienti}
          elements={clienti} 
          keysToShow={[
            'p_iva',
            'indirizzo_pec',
            'numero_telefono'
          ]}
        />
      </div>
    );
}

export default Clienti;