import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Alert,
  Button,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  ScaleIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  PrinterIcon,
  HomeIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { AvatarWithText } from "../atoms/AvatarWithText";
import { useNavigate } from "react-router-dom";
import { ROUTE_CLIENTI, ROUTE_HOME, ROUTE_LOGIN, ROUTE_NOLLEGGI_CONTRATTI, ROUTE_NOLLEGGI_FATTURE } from "../../Utils/routes";


const logout = () => {
  localStorage.removeItem('user');
  window.location.href = ROUTE_LOGIN;
}

export function SidebarWithLogo() {
  const [open, setOpen] = React.useState(0);
  const openAlert =true;
  const navigate = useNavigate();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Card className="h-[calc(100vh)] w-full max-w-[20rem] p-4 shadow-sm shadow-blue-gray-200 rounded-none bg-white">
      <div className="mb-2 flex items-center gap-4 p-4">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/e/e1/Olivetti_logo_2006.png"
          alt="brand"
          className="h-12"
        />
      </div>
      <List>
        <ListItem
          className="rounded-sm"
          style={{
            background:
              window.location.pathname === ROUTE_HOME
                ? "#d5353482"
                : "transparent",
          }}
          onClick={() => navigate(ROUTE_HOME)}
        >
          <ListItemPrefix>
            <HomeIcon strokeWidth={2} className="h-5 w-5" />
          </ListItemPrefix>
          Home
        </ListItem>
        <ListItem
          className="rounded-sm"
          style={{
            background:
              window.location.pathname === ROUTE_CLIENTI
                ? "#d5353482"
                : "transparent",
          }}
          onClick={() => navigate(ROUTE_CLIENTI)}
        >
          <ListItemPrefix>
            <UserGroupIcon strokeWidth={2} className="h-5 w-5" />
          </ListItemPrefix>
          Clienti
        </ListItem>
        <Accordion
          className="rounded-sm"
          open={open === 2}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 2 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className="p-0 rounded-none" selected={open === 2}>
            <AccordionHeader
              onClick={() => handleOpen(2)}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <PrinterIcon strokeWidth={2} className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                Noleggi
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-0">
            <List className="p-0">
              <ListItem
                className="rounded-none"
                style={{
                  background:
                    window.location.pathname === ROUTE_NOLLEGGI_CONTRATTI
                      ? "#d5353482"
                      : "transparent",
                }}
                onClick={() => navigate(ROUTE_NOLLEGGI_CONTRATTI)}
              >
                <ListItemPrefix className="pl-3">
                  <DocumentTextIcon strokeWidth={2} className="h-5 w-5" />
                </ListItemPrefix>
                Contratti
              </ListItem>
              <ListItem
                className="rounded-none"
                style={{
                  background:
                    window.location.pathname === ROUTE_NOLLEGGI_FATTURE
                      ? "#d5353482"
                      : "transparent",
                }}
                onClick={() => navigate(ROUTE_NOLLEGGI_FATTURE)}
              >
                <ListItemPrefix className="pl-3">
                  <ClipboardDocumentListIcon
                    strokeWidth={2}
                    className="h-5 w-5"
                  />
                </ListItemPrefix>
                Preventivi
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
      </List>
      <Alert open={openAlert} className="mt-auto flex gap-2 p-3 bg-transparent">
        <Button
          onClick={() => logout()}
          className="flex items-center gap-3 w-full mb-6 rounded-none bg-[#bc000ad9]"
          size="sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
            />
          </svg>
          Esci
        </Button>
        <AvatarWithText />
      </Alert>
    </Card>
  );
}
