import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import Canone from "./Canone";
import Riassunto from "./Riassunto";
import LetturaMacchina from "./LetturaMacchina";
import { useEffect, useState } from "react";
import axios from "axios";
import TrashCosts from "./TrashCosts";
import Cauzione from "./Cauzione";
import { Triangle } from "react-loader-spinner";
import {
  CreditCardIcon,
  PrinterIcon,
  FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import { PDFDownloadLink } from "@react-pdf/renderer";
import FatturaToPrint from "./FatturaToPrint";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import domToPdf from "dom-to-pdf";

const Fattura = () => {
  const { idPreventivo } = useParams();
  const [loadingPreventivo, setLoadingPreventivo] = useState(true);
  const [prodotti, setProdotti] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [preventivo, setPreventivo] = useState(null);
  const [contratto, setContratto] = useState(null);

  const getContratto = async (idContratto) => {
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contratti/noleggi/` + idContratto)
      .then((response) => {
        setContratto(response?.data);
        getCliente(response.data.id_cliente);
      });
  };

  const getCliente = async (idCliente) => {
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/clienti/` + idCliente)
      .then((response) => {
        setCliente(response?.data);
      });
  };

  const getPreventivo = async () => {
    setLoadingPreventivo(true);
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/preventivi/` + idPreventivo)
      .then((response) => {
        setPreventivo(response?.data);
        getProdottiContratto(response.data.id_contratto);
        getContratto(response?.data?.id_contratto);
        setTimeout(() => setLoadingPreventivo(false), 2000);
      });
  };

  const getProdottiContratto = async (idContratto) => {
    return await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/prodotti/contratto/` + idContratto)
      .then((response) => {
        setProdotti(response.data);
      });
  };

  const setPagamento = async () => {
    const toSend = {
      ...preventivo,
      penultime_letture: preventivo?.penultime_letture ?? [],
      pagato: !preventivo?.pagato,
    };
    
    return await axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/preventivi/` + idPreventivo, toSend).then(() => setPreventivo(toSend));
  };

  const stampaFile = async(download = false) => {
    const element = document.querySelectorAll(".da-stampare");
    const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    const imagesToAdd = [];

    const val = document.createElement("div");

    await element.forEach((node) => {
      const p_prime = node.cloneNode(true);
      p_prime.classList.add("w-[870px]");
      p_prime.classList.add("border-b-2");
      p_prime.classList.add("border-black");
      p_prime.classList.add("shadow-none");
      val.appendChild(p_prime);
    });
    pdf.html(val, {
    html2canvas: { scale: 0.47 },
    callback: doc => {
      if(download) {
        doc.save(
          `preventivo_${preventivo?.data_emissione}_${cliente?.ragione_sociale}.pdf`
        );
      } else {
        window.open(doc.output("bloburl"), "_blank");
      }
    },
    margin: [20, 20, 20, 20],
    autoPaging: 'text',
  });
  }

  useEffect(() => {
    getPreventivo();
  }, []);
  
  return (
    <div className="flex-1 p-4 overflow-auto max-h-[100vh]">
      <div className="flex items-center justify-between">
        <Typography className="my-2 mb-4" color="gray" variant="h5">
          PREVENTIVO FATTURA (#{preventivo?.id})
        </Typography>
        <div className="buttonsContainer flex items-center gap-2">
          <Button
            onClick={() => setPagamento()}
            disabled={loadingPreventivo}
            className={
              preventivo?.pagato
                ? "bg-[#bf4646] flex items-center gap-3 rounded-none"
                : "bg-[#4aa84a] flex items-center gap-3 rounded-none"
            }
            size="sm"
          >
            <CreditCardIcon strokeWidth={2} className="h-4 w-4" />
            {preventivo?.pagato ? "Segna come non pagata" : "Segna come pagata"}
          </Button>

          <Button
            onClick={() => stampaFile(true)}
            disabled={loadingPreventivo}
            className={"bg-[#bf4646] flex items-center gap-3 rounded-none"}
            size="sm"
          >
            <FolderArrowDownIcon strokeWidth={2} className="h-4 w-4" />
            Scarica
          </Button>
          <Button
            onClick={() => stampaFile()}
            disabled={loadingPreventivo}
            className={"bg-[#bf4646] flex items-center gap-3 rounded-none"}
            size="sm"
          >
            <PrinterIcon strokeWidth={2} className="h-4 w-4" />
            Stampa
          </Button>
        </div>
      </div>
      {loadingPreventivo ? (
        <Card className="rounded-none">
          <CardBody>
            <Triangle
              visible={true}
              height="48"
              width="48"
              color="#c62b38"
              secondaryColor="#d6888f"
              ariaLabel="rings-loading"
              wrapperStyle={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              }}
              wrapperClass=""
            />
          </CardBody>
        </Card>
      ) : (
        <>
          <Riassunto fattura={preventivo} cliente={cliente} />
          <Canone contratto={contratto} withIva={preventivo?.with_iva} />
          {preventivo?.trashCosts ? (
            <TrashCosts preventivo={preventivo} withIva={preventivo?.with_iva} />
          ) : undefined}
          {preventivo?.cauzione ? (
            <Cauzione preventivo={preventivo}/>
          ) : undefined}

          {preventivo?.["id_prodotti"].map((prodotto, index) => {
            const macchina = prodotti?.find((e) => e.id === prodotto);

            const ultima_lettura_macchina =
              preventivo?.ultime_letture?.[macchina?.id] ?? [];
            const penultima_lettura_macchina =
              preventivo?.penultime_letture?.[macchina?.id] ?? [];

            const numero_pag_color_ultima_lettura_macchina =
              ultima_lettura_macchina?.["pagine_col"];
            const numero_pag_b_n_ultima_lettura_macchina =
              ultima_lettura_macchina?.["pagine_b_n"];
            const numero_pag_color_penultima_lettura_macchina =
              penultima_lettura_macchina?.["pagine_col"] ?? 0;
            const numero_pag_b_n_penultima_lettura_macchina =
              penultima_lettura_macchina?.["pagine_b_n"] ?? 0;

            return (
              <LetturaMacchina
                indexMap={index}
                macchina={macchina}
                fattura={preventivo}
                ultimaLetturaMacchina={ultima_lettura_macchina}
                penultimaLetturaMacchina={penultima_lettura_macchina}
                numPagBiancoNeroUltimaLettura={
                  numero_pag_b_n_ultima_lettura_macchina
                }
                numPagBiancoNeroPenultimaLettura={
                  numero_pag_b_n_penultima_lettura_macchina
                }
                numPagColoriUltimaLettura={
                  numero_pag_color_ultima_lettura_macchina
                }
                numPagColoriPenultimaLettura={
                  numero_pag_color_penultima_lettura_macchina
                }
                pagine_incluse_nel_canone_b_n={contratto?.fogli_base_b_w * preventivo?.mesi_di_fatturazione}
                pagine_incluse_nel_canone_col={contratto?.fogli_base_col * preventivo?.mesi_di_fatturazione}
                macchine_totali={preventivo?.id_prodotti?.length}
                withIva={preventivo?.with_iva}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Fattura;
