export const STAMPANTE = {
  label: "Stampante",
  value: 0,
};

export const CATEGORIE_PRODOTTI = {
  [STAMPANTE.value]: STAMPANTE.label,
};

export const MACCHINA_A_COLORI = {
  label: "Macchina a colori",
  value: 1,
};

export const MACCHINA_BIANCO_E_NERO = {
  label: "Macchina bianco e nero",
  value: 0,
};

export const TIPOLOGIE_PRODOTTI = {
  [MACCHINA_A_COLORI.value]: MACCHINA_A_COLORI.label,
  [MACCHINA_BIANCO_E_NERO.value]: MACCHINA_BIANCO_E_NERO.label,
};

export const PERIODI_FATTURAZIONE = {
  1: "Mensile",
  2: "Bimestrale",
  3: "Trimestrale",
  4: "Quadrimestrale",
  6: "Semestrale",
  12: "Annuale",
};

export const NOLEGGIO_A_CONSUMO = {
  label: "Noleggio a consumo",
  value: 0,
};
export const NOLEGGIO_A_CANONE = {
  label: "Noleggio a canone",
  value: 1,
};

export const TIPI_CONTRATTO = {
  [NOLEGGIO_A_CANONE.value]: NOLEGGIO_A_CANONE.label,
  [NOLEGGIO_A_CONSUMO.value]: NOLEGGIO_A_CONSUMO.label,
};

export const STATI_PAGAMENTO = {
  true: "Pagata",
  1: "Pagata",
  false: "Non pagata",
  0: "Non pagata",
};