import React from "react";
import { BreadcrumbsWithIcon } from "../components/atoms/Breadcrumbs";
import { SimpleCard } from "../components/wrapper/SimpleCard";
import Example from "../components/wrapper/chart";
import { Typography } from "@material-tailwind/react";
import {
  ArrowPathIcon,
  CreditCardIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { ButtonWithMenu } from "../components/wrapper/ButtonWithMenu";

const Dashboard = () => {
  return (
    <div className="flex-1 p-4 max-h-[100vh]">
      <div className="header flex items-center justify-between pr-1">
        <div>
          <h1 className="text-3xl font-bold">
            <Typography className="my-2" variant="h4" color="black">
              Home
            </Typography>
          </h1>
          
        </div>
        <div>
          <ButtonWithMenu />
        </div>
      </div>
      <div className="w-full">
        <div className="flex gap-3 justify-between">
          <SimpleCard
            CardIcon={ArrowPathIcon}
            cardColor="#ab6ea1"
            bottomText={"Contratti vicini al rinnovo"}
            topText={2}
          />
          <SimpleCard
            CardIcon={CreditCardIcon}
            cardColor="#ff0000"
            bottomText={"Fatture non incassate"}
            topText={0}
          />
          <SimpleCard
            CardIcon={ExclamationTriangleIcon}
            cardColor="#ff8800"
            bottomText={"Macchine da controllare"}
            topText={20}
          />
        </div>
        <Example />
      </div>
    </div>
  );
};

export default Dashboard;
