import { Typography } from "@material-tailwind/react";

const TitoloBoxGrande = ({
    titolo
}) => {
    return (
        <Typography
            className="my-2 mb-4"
            color="gray"
            variant="lead"
            style={{ fontWeight: "bold", textDecoration: "underline" }}
          >
            {titolo}
          </Typography>
    );
}

export default TitoloBoxGrande;