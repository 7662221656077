import { Card, CardBody, Typography } from "@material-tailwind/react";
import TitoloBoxGrande from "../../../components/wrapper/TitoloBoxGrande";
import CampoBoxGrande from "../../../components/wrapper/CampoBoxGrande";
import Divisore from "../../../components/atoms/Divisore";
import Prezzo from "../../../components/wrapper/Prezzo";

const Canone = ({
    contratto,
    withIva
}) => {
    const canoneBase = contratto?.prezzo_base;
    const costoTotaleFatturazione = canoneBase * contratto?.periodo_fatturazione;
    const ivaTotaleFatturazione = withIva ? costoTotaleFatturazione * 0.22 : 0;
    const totaleFattura = costoTotaleFatturazione + ivaTotaleFatturazione;

    return (
      <Card className="my-4 rounded-none da-stampare">
        <CardBody>
          <TitoloBoxGrande titolo={"CANONE"} />

          <CampoBoxGrande
            titoloCampo={"Mesi di fatturazione"}
            valoreCampo={contratto?.["periodo_fatturazione"]}
          />

          <CampoBoxGrande titoloCampo={"Canone base"} tipo="custom">
            <Prezzo valore={canoneBase ?? 0.0} />
          </CampoBoxGrande>

          <CampoBoxGrande titoloCampo={"IVA"}>
            <Prezzo valore={ivaTotaleFatturazione} />
          </CampoBoxGrande>

          <Divisore />

          <CampoBoxGrande
            titoloCampo={"Totale (canone x mesi di fatturazione + IVA)"}
            tipo="custom"
          >
            <Prezzo valore={totaleFattura} variant="lead" color="green" />
          </CampoBoxGrande>
        </CardBody>
      </Card>
    );
}

export default Canone;